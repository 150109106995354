export default [
  {
    title: 'Dashboards',
    route: 'dashboard',
    icon: 'HomeIcon',
    action: 'browes',
    resource: 'dashboard',
  },
  {
    title: 'Waffarha',
    icon: 'RefreshCwIcon',
    children: [
      {
        title: 'Transactions',
        route: 'waffarha-transactions',
        action: 'browes',
        resource: 'waffarha',
      },
    ],
  },
  {
    title: 'Roles',
    route: 'roles',
    icon: 'GlobeIcon',
    action: 'browes',
    resource: 'roles',
  },
  {
    title: 'Users',
    icon: 'HomeIcon',
    children: [
      {
        title: 'Admin',
        route: 'users',
        action: 'browes',
        resource: 'admin_users',
      },
      {
        title: 'Merchant',
        route: 'merchant-users',
        action: 'browes',
        resource: 'admin_users',
      },
      {
        title: 'Customer',
        route: 'customer-users',
        action: 'browes',
        resource: 'admin_users',
      },
    ],
  },
  // {
  //   title: 'Shops',
  //   icon: 'ShoppingCartIcon',
  //   children: [
  //     {
  //       title: 'All Shops',
  //       route: 'merchants',
  //       action: 'browes',
  //       resource: 'merchants',
  //     },
  //     {
  //       title: 'Shops Types',
  //       route: 'merchant-types',
  //       action: 'browes',
  //       resource: 'merchant_types',
  //     },
  //     {
  //       title: 'Branches',
  //       route: 'branches',
  //       action: 'browes',
  //       resource: 'branches',
  //     },
  //   ],
  // },
  // {
  //   title: 'Offers',
  //   route: 'offers',
  //   icon: 'CreditCardIcon',
  //   action: 'browes',
  //   resource: 'offers',
  // },
  // {
  //   title: 'Vouchers',
  //   route: 'vouchers',
  //   icon: 'CreditCardIcon',
  //   action: 'browes',
  //   resource: 'offers',
  // },
  {
    title: 'User Conatct',
    route: 'friends',
    icon: 'UsersIcon',
    action: 'browes',
    resource: 'friends',
  },
  {
    title: 'Places',
    icon: 'GlobeIcon',
    children: [
      {
        title: 'Countries',
        route: 'countries',
        action: 'browes',
        resource: 'countries',
      },
      {
        title: 'States',
        route: 'states',
        action: 'browes',
        resource: 'states',
      },
      {
        title: 'Cities',
        route: 'cities',
        action: 'browes',
        resource: 'cities',
      },
      {
        title: 'Areas',
        route: 'areas',
        action: 'browes',
        resource: 'areas',
      },
    ],
  },
  // {
  //   title: 'Transaction',
  //   icon: 'GitPullRequestIcon',
  //   children: [
  //     {
  //       title: 'C-C',
  //       route: 'transactions-c-c',
  //       action: 'browes',
  //       resource: 'transactions',
  //     },
  //     {
  //       title: 'M-C',
  //       route: 'transactions-m-c',
  //       action: 'browes',
  //       resource: 'transactions',
  //     },
  //     {
  //       title: 'C-M',
  //       route: 'transactions-c-m',
  //       action: 'browes',
  //       resource: 'transactions',
  //     },
  //     {
  //       title: 'A-C',
  //       route: 'transactions-a-c',
  //       action: 'browes',
  //       resource: 'transactions',
  //     },
  //     {
  //       title: 'A-M',
  //       route: 'transactions-a-m',
  //       action: 'browes',
  //       resource: 'transactions',
  //     },
  //     {
  //       title: 'M-M',
  //       route: 'transactions-m-m',
  //       action: 'browes',
  //       resource: 'transactions',
  //     },
  //     {
  //       title: 'P-C',
  //       route: 'transactions-p-c',
  //       action: 'browes',
  //       resource: 'transactions',
  //     },
  //     {
  //       title: 'Send Money',
  //       route: 'transactions-send-money',
  //       action: 'send',
  //       resource: 'money',
  //     },
  //   ],
  // },
  // {
  //   title: 'Configs',
  //   route: 'configs',
  //   icon: 'GridIcon',
  //   action: 'browes',
  //   resource: 'configs',
  // },
  {
    title: 'One Card',
    icon: 'GitlabIcon',
    children: [
      {
        title: 'Gaming Categories',
        route: 'gaming-categories',
        action: 'browes',
        resource: 'gaming_category',
      },
      {
        title: 'Gift Card',
        route: 'gift-cards',
        action: 'browes',
        resource: 'gaming_category',
      },
      {
        title: 'Entertainments',
        route: 'entertainments',
        action: 'browes',
        resource: 'gaming_category',
      },
      {
        title: 'products',
        route: 'gaming-products',
        action: 'browes',
        resource: 'gaming_product',
      },
    ],
  },
  {
    title: 'Utilities',
    route: 'utilities',
    icon: 'FramerIcon',
    action: 'browes',
    resource: 'utility',
  },
  {
    title: 'Donations',
    route: 'donations',
    icon: 'BoxIcon',
    action: 'browes',
    resource: 'donations',
  },
  {
    title: 'Promo Code',
    route: 'promoCodes',
    icon: 'TagIcon',
    action: 'create',
    resource: 'promo',
  },
  {
    title: 'Settings',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'General',
        route: 'settings',
        action: 'browes',
        resource: 'configs',
      },
      {
        title: 'Payment Types',
        route: 'providers',
        action: 'browes',
        resource: 'providers',
      },
      // {
      //   title: 'Module Settings',
      //   route: 'moduel',
      //   action: 'browes',
      //   resource: 'configs',
      // },
    ],
  },
  // {
  //   title: 'Merchant Requests',
  //   route: 'merchant-requests',
  //   icon: 'GitPullRequestIcon',
  //   action: 'browes',
  //   resource: 'configs',
  // },
  // {
  //   title: 'Cashouts',
  //   route: 'cashouts',
  //   icon: 'DollarSignIcon',
  //   action: 'browes',
  //   resource: 'configs',
  // },
  // {
  //   title: 'Cashout Types',
  //   route: 'cashout-types',
  //   icon: 'DollarSignIcon',
  //   action: 'browes',
  //   resource: 'configs',
  // },
  // {
  //   title: 'Offer Categories',
  //   route: 'offer-categories',
  //   icon: 'DollarSignIcon',
  //   action: 'browes',
  //   resource: 'configs',
  // },
  {
    title: 'Tickets',
    route: 'tickets',
    icon: 'HelpCircleIcon',
    action: 'browes',
    resource: 'tickets',
  },
  {
    title: 'Ticket Categories',
    route: 'ticket-categories',
    icon: 'ListIcon',
    action: 'browes',
    resource: 'category',
  },
  {
    title: 'Fees Profiles',
    route: 'fees-profiles',
    icon: 'DollarSignIcon',
    action: 'browes',
    resource: 'fees',
  },
  {
    title: 'Notifications',
    route: 'notifications',
    icon: 'BellIcon',
    action: 'send',
    resource: 'notification',
  },
  {
    title: 'Services',
    route: 'services',
    icon: 'FileTextIcon',
    action: 'browes',
    resource: 'services',
  },
  {
    title: 'Config Services',
    route: 'config-services',
    icon: 'SlidersIcon',
    action: 'browes',
    resource: 'config',
  },
  {
    title: 'FAQs',
    route: 'faqs',
    icon: 'HelpCircleIcon',
    action: 'browes',
    resource: 'faqs',
  },
  {
    title: 'Financial Reports',
    route: 'financial-reports',
    icon: 'LayersIcon',
    action: 'browes',
    resource: 'reports',
  },

  {
    title: 'Cashcall',
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'Cashcall Wallet',
        route: 'cashcall-wallet',
        action: 'browes',
        resource: 'cashcall_balance',
      },
      {
        title: 'Failed Transactions',
        route: 'failed-transactions',
        action: 'browes',
        resource: 'cashcall_transaction',

      },
    ],
  },
  {
    title: 'Waffarha',
    icon: 'RefreshCwIcon',
    children: [
      {
        title: 'Refund',
        route: 'refund-waffarha',
        action: 'refund',
        resource: 'waffarha_order',
      },
      {
        title: 'Order Details',
        route: 'order-details',
        action: 'browes',
        resource: 'waffarha_order',

      },
    ],
  },

  // {
  //   title: 'Damen Service',
  //   icon: 'ServerIcon',
  //   children: [
  //     {
  //       title: 'Category Aggregator',
  //       route: 'damen-categories',
  //       action: 'browes',
  //       resource: 'cat_aggregator',
  //     },
  //     {
  //       title: 'Biller Aggregator',
  //       route: 'damen-billers',
  //       action: 'browes',
  //       resource: 'providers',
  //     },
  //     {
  //       title: 'Service Aggregator',
  //       route: 'damen-services',
  //       action: 'browes',
  //       resource: 'ser_aggregator',
  //     },
  //     {
  //       title: 'Pending Transactions',
  //       route: 'pending-transactions',
  //       action: 'browes',
  //       resource: 'configs',
  //     },
  //   ],
  // },

  // {
  //   title: 'Notifications',
  //   route: 'notifications',
  //   icon: 'BellIcon',
  //   action: 'browse',
  //   resource: 'notifications',
  // },
]
